import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-info-icon',
    imports: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <svg
            fill="none"
            height="100%"
            viewBox="0 0 18 18"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 3.5C5.96239 3.5 3.5 5.96253 3.5 9C3.5 12.0378 5.96253 14.5001 9 14.5001C12.0378 14.5001 14.5 12.0378 14.5 9C14.5 5.96238 12.0378 3.5 9 3.5ZM9.00965 6.47619C9.27189 6.47619 9.46861 6.68051 9.46861 6.9505C9.46861 7.19343 9.27189 7.40931 9.00205 7.40931C8.75911 7.40931 8.53914 7.19343 8.53914 6.9505C8.53929 6.68051 8.75911 6.47619 9.00965 6.47619ZM9.93531 11.231H8.18827V10.9882C8.61637 10.9303 8.67034 10.8994 8.67034 10.4017V8.94793C8.67034 8.48137 8.63187 8.46601 8.23844 8.40429V8.17277C8.66273 8.1188 9.08293 8.02622 9.44535 7.89897V10.4016C9.44535 10.8915 9.48426 10.93 9.93531 10.9881V11.231Z"
                fill="currentColor" />
        </svg>
    `
})
export class InfoIconComponent {}
